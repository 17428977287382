import React from "react";
import deskTwoBanner from "../../../imagee/secondBanner.jpg";
import ourMissionLogo from "../../asset/metaEvents/eventsLogo.png";
import TopBannerLogo from "../../../imagee/deskTwoLogo.jpg";
import MarginOne from "../../../imagee/missionMarginOne.jpg";
import MarginTwo from "../../../imagee/missionMargintwo.jpg";
import sketchNew from "../../../imagee/artist-drawing-anime-w.jpg";
import skatch from "../../../imagee/skatch.jpg";
import "./Banner.css";
import Card from "../Card/Card";
import { Container } from "react-bootstrap";
import ContactUs from "../ContactUs/ContactUs";
import Footer from "../../Shared/Footer/Footer";
import Navigation2 from "../../Shared/Navigation2/Navigation2";

const Banner = () => {
  const [showButton, SetshowButton] = React.useState(false);
  return (
    <div className="desktop2-beg" id="home">
      <div className="banner recuiter_bg">
      <Navigation2 mintPage/>
        <img className="artistRecruit-banner d-md-block d-none" src={deskTwoBanner} alt="" />
        <img
          className="artistRecruite-banner-logo img-fluid"
          src={TopBannerLogo}
          alt=""
        />
      </div>

      <div className="ourmission-style bg-white">
        <div className="our-mission-logo text-center pt-5">
          <img src={ourMissionLogo} alt="" />
        </div>
        <div>
          <p className="mission-text pb-2">OUR MISSION</p>
          <img className="img-fluid" src={MarginOne} alt="" />
          <p className="mission-description px-4">
          作為資深的動漫和遊戲愛好者，我們在 ACG 行業認識了很多藝術家，而我們在與他們的談話中最常聽見的過往就是如何從兼職藝術家進步到全職藝術家。他們最初充滿熱情和雄心壯志。然而，他們沒有想到，這裡只會有不斷出現的障礙和無盡的孤獨，就像一個孤獨的旅行者在沙漠中的海市蜃樓中尋找綠洲。
「這樣做有前途嗎？」 {showButton && `「這是什麼？你畫的是真正的藝術嗎？” “每天畫這個不會餓死嗎？”……這些都是最常被問到的問題，每次都很隨意，但每次都讓人心碎。 “我每天都在想，要不要繼續下去？”一位藝人朋友笑著說，但背後的悲傷是毋庸置疑的。
其中一些藝術家有幸積累了更多的藝術品並變得更加出名。慢慢地，他們找到了方法，並且可以每天繼續實現他們的夢想。
不幸的是，很多才華橫溢的藝術家無法抗拒親友的壓力和生活的殘酷，不得不慢慢放棄自己的夢想。`}
         </p>
          <div className="my-4 text-center">
            {showButton ? null : (
              <button
                type="button"
                className="learn-more mx-auto bg-pr text-dark"
                onClick={() => SetshowButton(true)}
              >
                Learn More <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </button>
            )}
          </div>
          {showButton ? (
            <p className="mission-description px-4">
             目前藝術界對 NFT 和元宇宙知之甚少，但我們相信通過 NFT 的去中心化技術，可以在虛擬世界中擁有數字資產的“真實所有權”。2021 年初，更多知名品牌和藝術家開始發行自己的 NFT，越來越多的人逐漸接受了這個新穎的概念。 NFT 不僅僅是一張圖片。它代表著獨特的特色和永久保存的魅力。我們相信這對 ACG 藝術家來說是一個絕佳的機會。我們希望通過協助更多的 ACG 藝人，通過 NFT 提升知名度。除了通過出售他們的數位藝術品謀生外，我們也願意提供更多的協助
 
            </p>
          ) : null}
          <img className="img-fluid pb-5" src={MarginTwo} alt="" />
          <Container>
            <img className="skatch" src={sketchNew} alt="" />
          </Container>
        </div>
      </div>
      <div className="back-style">
        <Container className=" pb-5">
          <div className="container-color text-center">
            <div className="our-mission-logo pt-5">
              <img src={ourMissionLogo} alt="" />
            </div>
            <div>
              <span className="acg-artist">ACG Artist Support Community</span>{" "}
              <br />
              <br />
              <p className="acg-artist-description px-4">
              我們的使命和價值是為ACG藝術創作者們提供後盾，會想這麼主要有幾個原因:
我們知道全球疫情的影響，使創作者更難在現實生活中販售作品；這也是為什麼我們希望帶入web 3.0，為這群經常被忽略的藝術作家們，提供更多的可能性. 
我們將為ACG創作者，提供製作自己NFT的知識與工具，並協助他們在專業的領域不斷精進。
              </p>
            </div>
          </div>
        </Container>
        <div className="card-area-style">
          <Card></Card>
        </div>
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Banner;
