import {useEffect, useState} from "react";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";
import giveWayLogo from "../../asset/giveway/giveWayLogo.png";
import eventLogo from "../../asset/metaEvents/eventsLogo.png";
import event1 from "../../asset/offlineEvents/img1.png";
import event2 from "../../asset/offlineEvents/img2.png";
import event3 from "../../asset/offlineEvents/img3.png";
import event4 from "../../asset/offlineEvents/img4.png";
import event5 from "../../asset/offlineEvents/img5.png";
import event6 from "../../asset/offlineEvents/img6.png";
import event7 from "../../asset/offlineEvents/img7.png";
import "./OfflineEvents.css";

// 
const OfflineEvents = () => {
  const [showButton, SetshowButton] = useState(false);

  // 
  const ToggleText = () => {
    SetshowButton(!showButton);
  };

  
  // const [windowSize, setWindowSize] = useState(null);

  // useEffect(() => {
  //   const handleResize = () => {
  //     let size =window.innerWidth;

  //    if(size > 768){
  //     SetshowButton(true);
  //   }else{
  //     SetshowButton(false);
  //    }
  //     // setWindowSize(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   window.addEventListener("load", function () {
  //     handleResize()
  //   });

  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);
  return (
    <div className="offlineEvents" id="offline">
      <Container>
        <Fade bottom>
          <div>
            <img
              className="mt-5 img-fluid pb-4 pt-5"
              src={giveWayLogo}
              alt=""
            />
            <p className="giveWayHeading text-uppercase">
              線下賦能和活動
            </p>
          </div>
        </Fade>
        <div>
          <Fade bottom>
            <img className="mt-5" src={eventLogo} alt="" />
            <p className="lotery-text ">全球動漫展支援</p>
          </Fade>
        </div>
        {/* events card div/// */}
        <section className="offline-event-wraper">
          <Fade bottom>
            <div>
              <p className="support-text" style={{ opacity: ".8" }}>
              ACG展覽是愛好者的盛會。可惜的是，粉絲和繪師經常因為不便而選擇不吃不喝。我們將會選擇在全球特定的 ACG 展覽上為我們的持有者提供免費支援。可能是租一個展位或一輛餐車。


              </p>
              <div className="off-event-img">
                <img src={event1} alt="" />
                {showButton && <img className="d-block d-md-none" src={event2} alt="" />}
                 <img className="d-md-block d-none" src={event2} alt="" />
              </div>
              {/* mobile content */}
              {showButton && (
                <div className="d-md-none d-block">
                  <div>
                    <p
                      className={
                        showButton
                          ? "support-text show-text-active"
                          : "support-text show-text"
                      }
                      style={{ opacity: ".8" }}
                    >
                      我們將為每個人提供免費瓶裝水，如果他們願意，他們也可以向我們購買餐盒。但是對於 FREEDEN NFT 持有者來說，所有餐點都是免費的，並且他們有優先拿取餐盒的權利。我們還將提供更多的食物和飲料選擇，如咖啡、茶、小吃等。還有一個為FREEDEN NFT持有者提供的專屬休息區和一個由VIP接待員服務的藝術家休息區。我們會讓動漫愛好者在參加他們的特別 ACG 活動時感受到支持。 FREEDEN 將出現在全球頂級 ACG 相關展覽上。 NFT 持有者也能分享在這些展覽中產品銷售的利潤"
                    </p>
                  </div>
                  <div className="off-event-img">
                    <img src={event3} alt="" />
                    <img src={event4} alt="" />
                  </div>
                  <div>
                    <p className="support-title">免費代排服務：</p>
                    <p className="support-text" style={{ opacity: ".8" }}>
                    你看到這些人疲憊的眼睛了嗎？
恭喜那些一直在排隊購買限量 ACG 產品的人；我們知道在飢餓和睡眠不足的情況下還要大包小包的排隊是一件非常痛苦的事情。只要有限量產品需要在特定的展覽中排隊，我們將抽取五名幸運兒，並為他們提供免費排隊服務。得獎者這也可以將這個服務轉售或贈予他人
                    </p>
                    
                   <p className="support-title">線下展覽派對</p>
                    <p className="support-text" style={{ opacity: ".8" }}>
                    為了讓粉絲有機會與自己喜歡的藝術家互動，我們將在展覽結束後舉辦派對。

                    </p>
                  </div>
                  <div className="off-event-img">
                    <img src={event5} alt="" />
                    <img src={event6} alt="" />
                  </div>
                  <div>
                    <p className="support-text" style={{ opacity: ".8" }}>
                    我們將邀請藝術家加入我們的聚會，與我們一起慶祝同樂，以加強藝術家和粉絲之間的聯繫，並在聚會上舉辦特別活動和提供獎品。

                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <img className="w-100" src={event7} alt="" />
                  </div>
                  <div>
                    <p className="support-title">所有費用都會由 FREEDEN 支出。</p>
                  </div>
                </div>
              )}
              {/* desktop content */}
                <div className="d-md-block d-none">
                  <div>
                    <p
                      className={
                        showButton
                          ? "support-text show-text-active"
                          : "support-text show-text"
                      }
                      style={{ opacity: ".8" }}
                    >
                     我們將為每個人提供免費瓶裝水，如果他們願意，他們也可以向我們購買餐盒。但是對於 FREEDEN NFT 持有者來說，所有餐點都是免費的，並且他們有優先拿取餐盒的權利。我們還將提供更多的食物和飲料選擇，如咖啡、茶、小吃等。還有一個為FREEDEN NFT持有者提供的專屬休息區和一個由VIP接待員服務的藝術家休息區。我們會讓動漫愛好者在參加他們的特別 ACG 活動時感受到支持。 FREEDEN 將出現在全球頂級 ACG 相關展覽上。 NFT 持有者也能分享在這些展覽中產品銷售的利潤
                    </p>
                  </div>
                  <div className="off-event-img">
                    <img src={event3} alt="" />
                    <img src={event4} alt="" />
                  </div>
                  <div>
                    <p className="support-title">免費代排服務：</p>
                    <p className="support-text" style={{ opacity: ".8" }}>
                    你看到這些人疲憊的眼睛了嗎？
恭喜那些一直在排隊購買限量 ACG 產品的人；我們知道在飢餓和睡眠不足的情況下還要大包小包的排隊是一件非常痛苦的事情。只要有限量產品需要在特定的展覽中排隊，我們將抽取五名幸運兒，並為他們提供免費排隊服務。得獎者這也可以將這個服務轉售或贈予他人
 
                    </p>
                    <p className="support-title">線下展覽派對</p>
                    <p className="support-text" style={{ opacity: ".8" }}>
                    為了讓粉絲有機會與自己喜歡的藝術家互動，我們將在展覽結束後舉辦派對。

                    </p>
                  </div>
                  <div className="off-event-img">
                    <img src={event5} alt="" />
                    <img src={event6} alt="" />
                  </div>
                  <div>
                    <p className="support-text" style={{ opacity: ".8" }}>
                    我們將邀請藝術家加入我們的聚會，與我們一起慶祝同樂，以加強藝術家和粉絲之間的聯繫，並在聚會上舉辦特別活動和提供獎品。
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <img className="w-100" src={event7} alt="" />
                  </div>
                  <div>
                    <p className="support-title">所有費用都會由 FREEDEN 支出。</p>
                  </div>
                </div>
                {/*  */}
              <div className="mt-2 d-md-none d-block text-center">
                {showButton ? null : (
                  <button
                    type="button"
                    className="learn-more mx-auto"
                    onClick={ToggleText}
                  >
                  Learn More { " " }
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                   {/* {!showButton ? "Learn More " : "Minimize "} {" "} */}
                   {/* {!showButton ?
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    :
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>} */}
                  </button>
                 )} 
              </div>
            </div>
          </Fade>
        </section>
        {/* <a href="#voting"><button className='scrollTop mt-3' >↡</button></a> */}
      </Container>
    </div>
  );
};

export default OfflineEvents;
