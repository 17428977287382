import React from "react";
import Fade from "react-reveal/Fade";
import aboutUsArt from "../../asset/image/aboutUsArt.png";
import meduim from "../../asset/image/meduimLogo.png";
// import artist from '../../asset/image/artist.png'
import "./AboutUs.css";
const AboutUs = () => {
  return (
    <div id="AboutUs" className="aboutUs">
      <img src={aboutUsArt} className="aboutus-img" alt="" />

      <Fade bottom>
        <p className="aboutus-text">
          FREEDEN 是一個令人興奮的 NFT 項目，旨在為全球 ACG
          藝術家和愛好者提供可揮灑創意和沉浸的體驗。在一個創作者越來越難生存的世界裡，大多數藝術家在追逐夢想的同時，也同時為了生活而奮鬥，尤其是ACG藝術家，他們屬於藝術創作群體中較易被忽視和遺忘的群體。因此我們想要提供一個社群，讓粉絲可以在我們的虛擬世界俱樂部中，收集和分享他們最喜歡的
          ACG 數字藝術作品；同時，藝術家仍然可以通過我們的 Create to Earn
          機制謀生。通過由全球十位知名ACG藝術家設計的 6,000 個生成藝術
          NFT，我們將能夠實現我們的願景。我們為NFT持有者提供了獨特的賦能與機會……我們將一起發現FREEDEN。
        </p>
      </Fade>
      <Fade bottom>
        <div className="social-section">
          {/* <a href="http://www.facebook.com/freeden.io" target="blank">
            <i class="fa-brands fa-facebook-f"></i>
          </a> */}
          <a href="https://twitter.com/freedenio" target="blank">
            <i class="fa-brands fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/freeden.io/" target="blank">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a
            href="https://discord.gg/rnYW6fu3zv"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa-brands fa-discord"></i>
          </a>
          <a
            href="https://medium.com/@freeden"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i>
              <img src={meduim} alt="" />
            </i>
          </a>
        </div>
      </Fade>
    </div>
  );
};

export default AboutUs;
