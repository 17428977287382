import { useState } from "react";
import "./Bonuse.css";
import logo from "../../asset/image/bonus-logo.png";
import img1 from "../../asset/image/b-img-1.png";
import img2 from "../../asset/image/b-img-2.png";
import img3 from "../../asset/image/b-img-3.png";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";

const Bonuse = () => {
  const [showBonus, SetshowBonus] = useState(false);
  const ToggleBonusText = () => {
    SetshowBonus(!showBonus);
  };
  return (
    <div className="bonuse-section pb-5">
      <Container className="section-wraper pb-5">
        <Fade bottom>
          <div className="logo-part">
            <img src={logo} alt="" />
            <p className="giveWayHeading pt-4">SPECIAL BONUSES</p>
          </div>
        </Fade>

        <div className="bonuse-box mx-md-5" id="bonus">
          <Fade bottom>
            <div className="bonuse-content">
              <div className="pe-md-4 pt-5 left">
                <p className="bonus-title">
                  持續質押 FREEDEN NFT 將會有特殊獎勵。
                </p>
                {/* <p className={showBonus ? 'bonus-description show-bonus-text-active' : 'bonus-description show-bonus-text'}>The 2D and 3D lottery activities have different prizes, and the 3D lottery */}
                <p className={"bonus-description"}>
                  2D和3D抽獎活動有不同的獎項，3D NFT 所抽的獎項會比 2D NFT
                  的更好
                </p>
                {!showBonus ? (
                  <div className="bonus-learn-more">
                    <button
                      type="button"
                      className="learn-more"
                      onClick={ToggleBonusText}
                    >
                      Learn More{" "}
                      <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="ps-md-4 right">
                <img className="bonuse-img" src={img1} alt="" />
              </div>
            </div>
            {/* for desktop */}
            <div className="d-md-block d-none">
              <div className="bonuse-content">
                <div className="pe-md-4 left">
                  <img className="bonuse-img" src={img2} alt="" />
                  {/* <p>Mouse Pads / T-Shirts / Hoodies / Coaster / Tote Bag / Pillow</p> */}
                </div>
                <div className="ps-md-4 right">
                  <p className="bonus-title">兩個 NFT 持有者</p>
                  <p className="bonus-description">
                    1.您將獲得限量的獨家NFT配件，賦予你購買的NFT獨特而稀有的外觀。
                    <br /> <br />
                    2.您可以從以下六個選項中選擇兩個作為紀念品；滑鼠墊 / T恤 /
                    連帽衫 / 杯墊 / 手提袋 / 抱枕
                    我們將負責安排運送事宜；但收件人必須支付運費。
                    {/* <p>Souvenirs: Holders with two NFTs can choose two out of five tangible products (as above);
                        FreEden will take care of the delivery, and the buyers have to cover the shipping charges.</p> */}
                  </p>
                </div>
              </div>
              <div className="bonuse-content">
                <div className="pe-md-4 pt-5 left">
                  <p className="bonus-title">三個 NFT 持有者</p>
                  <p className="bonus-description">
                    1.您將獲得有限的獨家NFT配件，賦予NFT獨特而稀有的外觀。
                    <br />
                    2.您可以從以下六個選項中選擇五個作為紀念品；滑鼠墊 / T恤 /
                    連帽衫 / 杯墊 / 手提袋 / 抱枕
                    我們將負責安排運送事宜；但收件人必須支付運費。
                    <br /> <br />
                    <span className="fs-5">每週抽獎：</span>
                    持有更多 FREEDEN NFT 的持有者有更高的獲勝機會。
                    <br /> <br />
                    <span className="fs-5"> 獎品：</span> <br />
                    加密貨幣：穩定幣 / ETH / BNB / 其他加密貨幣 <br />
                    數位點數卡：亞馬遜/Netflix/Steam點數卡等 <br />
                    實體獎品：鼠標墊/移動電源/連帽衫/T 卹/帽子
                  </p>
                </div>
                <div className="ps-md-4 right">
                  <img className="bonuse-img" src={img3} alt="" />
                </div>
              </div>
            </div>

            {/* for mobile */}
            <div className="d-md-none d-block">
              {showBonus ? (
                <>
                  <div className="bonuse-content">
                    <div className="pe-md-4 left">
                      <img className="bonuse-img" src={img2} alt="" />
                      {/* <p>Mouse Pads / T-Shirts / Hoodies / Coaster / Tote Bag / Pillow</p> */}
                    </div>
                    <div className="ps-md-4 right">
                      <p className="bonus-title">兩個 NFT 持有者</p>
                      <p className="bonus-description">
                        1.您將獲得限量的獨家NFT配件，賦予你購買的NFT獨特而稀有的外觀。
                        <br /> <br />
                        2.您可以從以下六個選項中選擇兩個作為紀念品；滑鼠墊 / T恤
                        / 連帽衫 / 杯墊 / 手提袋 / 抱枕
                        我們將負責安排運送事宜；但收件人必須支付運費。
                        {/* <p>Souvenirs: Holders with two NFTs can choose two out of five tangible products (as above);
                        FreEden will take care of the delivery, and the buyers have to cover the shipping charges.</p> */}
                      </p>
                    </div>
                  </div>

                  <div className="bonuse-content">
                    <div className="pe-md-4 pt-5 left">
                      <p className="bonus-title">三個 NFT 持有者</p>
                      <p className="bonus-description">
                        1.您將獲得有限的獨家NFT配件，賦予NFT獨特而稀有的外觀。
                        <br />
                        2.您可以從以下六個選項中選擇五個作為紀念品；滑鼠墊 / T恤
                        / 連帽衫 / 杯墊 / 手提袋 / 抱枕
                        我們將負責安排運送事宜；但收件人必須支付運費。
                        <br /> <br />
                        <span className="fs-5">每週抽獎：</span>
                        持有更多 FREEDEN NFT 的持有者有更高的獲勝機會。
                        <br /> <br />
                        <span className="fs-5"> 獎品：</span> <br />
                    加密貨幣：穩定幣 / ETH / BNB / 其他加密貨幣 <br />
                    數位點數卡：亞馬遜/Netflix/Steam點數卡等 <br />
                    實體獎品：鼠標墊/移動電源/連帽衫/T 卹/帽子
                      </p>
                    </div>
                    <div className="ps-md-4 right">
                      <img className="bonuse-img" src={img3} alt="" />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </Fade>
        </div>
      </Container>
      {/* <a href="#ourTeam"><button className='scrollTop mt-3'  style={{position:"relative",zIndex:"2"}}>↡</button></a> */}
    </div>
  );
};

export default Bonuse;
