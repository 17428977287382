import './App.css';
import HomeParents from './Component/Home/HomeParents/HomeParents';
import Mint from './Component/Mint/Mint';
import Banner from './Component/ArtistRecruit/Banner/Banner';
import { useEffect } from "react";
import { useLocation , Routes, Route} from "react-router-dom";


function App() {
  return (
    <div className="App">
     <Routes>
        <Route path="/" element={<HomeParents />} />
        <Route path="/artistRecruit" element={<Banner/>} />
        <Route path="/mint" element={<Mint/>} />
      </Routes>
      
      <ScrollToTop />
    </div>
  );
}



function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
export default App;
