import { useState } from "react";
import { Link } from "react-router-dom";

const Navigation2 = ({ mintPage }) => {
  const [menu, setMenu] = useState(false);

  const closeMenu = () => {
    setMenu(false);
  };
  return (
    <>
    <button onClick={()=> setMenu(!menu)} id="cs_menuToggle" className={menu ? "opened_nav" : ""}>
        <span></span>
        <span></span>
        <span></span>
      </button>
    <nav id="cs_navigation" className={menu ? "opened_nav" : ""}>
      <ul id="cs_menu">
        <Link onClick={closeMenu} to="/">
          <li>首頁</li>
        </Link>
        {mintPage ? (
          <Link onClick={closeMenu} to="/mint">
            <li>NFT鑄造專區</li>
          </Link>
        ) : (
          <Link onClick={closeMenu} to="/artistRecruit">
            <li>藝術家招募</li>
          </Link>
        )}
         <hr />
        <p className="text_pr mb-3">
        語言選擇
        </p>
        <a href="https://freeden.io/" rel="noreferrer"><li>{">"} English</li></a>
        <a href="https://jp.freeden.io/" rel="noreferrer"><li>{"> "} 日本語</li></a>
        </ul>
    </nav>
    
   {menu && <div className="nav_backdrop" onClick={closeMenu}></div>}
   </>
  );
};

export default Navigation2;
