import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {  Autoplay, Pagination } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../asset/ourTeam/teamLogo.png";
import topLine from "../../asset/metaEvents/topLine.png";
import bottomLine from "../../asset/metaEvents/bottomLine.png";
import img1 from "../../asset/ourTeam/Rayj.png";
import img2 from "../../asset/ourTeam/Taichi.png";
import img3 from "../../asset/ourTeam/TojunnS.png";
import img4 from "../../asset/ourTeam/Kenji.png";
import img5 from "../../asset/ourTeam/ShiShi.png";
import img6 from "../../asset/ourTeam/Shaka.png";
import "./OurTeam.css";
import { Fade } from "react-reveal";

const OurTeam = () => {
  let teamData = [
    {
      name: `Ray J`,
      img: img1,
      role: `創始人`,
      body: `
      Ray 擁有多年在大型科技企業擔任部門領導和主管的經驗，熟悉雲端和互聯網技術戰略思維。他也是區塊鏈愛好者和 NFT 投資者，總是渴望學習更多的知識。他最近最喜歡的動漫是《咒術迴戰》和《我的英雄學院》。
      `
    },
    {
      name: `TaiChi`,
      img: img2,
      role: `品牌策略經理`,
      body: `
Taichi 是一位會說日語、英語和中文三種語言的日本動漫品牌大使。他對如何融合各種文化的優點有著深刻的理解。這讓他建立了一個資源豐富的跨國KOL 網絡。他致力於帶領FREEDEN團隊打造一個可以團結動漫藝術家和愛好者的平台。
      `
    },
    {
      name: `Tojunn S`,
      img: img3,
      role: `品牌行銷經理`,
      body: `
輿情監控分析&口碑行銷策略專家、長期投資人、Gamefi&NFT觀察者，熱愛ARPG和開放世界遊戲，熱血王道番鐵粉。目前正在利用自己的專業普及 NFT 與元宇宙。
      `
    },
    {
      name: `Kenji W`,
      img: img4,
      role: `專案顧問`,
      body: `
      
Kenji 是一位經驗豐富的加密貨幣玩家；多年來，他還領導了一個專業的加密研究團隊。他還熱衷於開放世界生存和 MMORPG 遊戲，是《進擊的巨人》、《EVA》、《鋼彈 W》和《浪客劍心》的忠實粉絲。
      `
    },
    {
      name: `ShiShi`,
      img: img5,
      role: `品牌社群經理`,
      body: `
擁有多年ACG文化研究經驗。並與來自世界各地的藝術家保持深厚的友誼。他對ACG藝術有獨到的見解，也擅長社群規劃。他的願景是創建一個無國界社群，能夠吸引並聚集來自不同文化或國家的 ACG 愛好者。
      `
    },
    {
      name: `Shaka O`,
      img: img6,
      role: `品牌技術專家`,
      body: `
      Shaka是一位熱愛動漫文化和背景研究的動漫愛好者。基於多年的加密數據和技術研究，他是一位經驗豐富的加密交易專家。他也是一個硬核遊戲玩家，喜歡團隊合作克服挑戰。
      `
    },
  ]
  return (
    <div className="ourteam-section" id="ourTeam">
      <Container>
        <Fade bottom>
          <div>
            <img className="img-fluid mt-5 pt-3" src={logo} alt="" />
          </div>
        </Fade>
        <div className="ourteam mt-4 pb-5">
          <img src={topLine} alt="" />
          {/*  desktop card style for team*/}
          <div className="desktop-team-members">
            <Row className="card-wraper g-4">
              {teamData?.map((v, i)=> (
              <Col key={i} className="col-md-6">
                <Fade bottom>
                  <div className="team-card mx-5 my-3">
                    <img className="img-fluid card-img" src={v?.img} alt={v?.name} />
                    <p className="team-title">{v?.name} </p>
                    <p>({v?.role})</p>
                    <p className="team-des">
                     {v?.body}
                    </p>
                  </div>
                </Fade>
              </Col>
              ))}
            </Row>
          </div>
          {/*  mobile slider for team */}
          <div className="mobile-team-section-swiper">
            <Swiper
            slidesPerView={1}
            spaceBetween={24}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper pb-3"
            >
              {teamData?.map((v, i) => (
              <SwiperSlide key={i}>
                <div className="team-card mx-auto">
                  <div className="team-member-img">
                    <img className="img-fluid card-img" src={v?.img} alt={v?.name} />
                  </div>
                  <p className="team-title">{v?.name}</p>
                  <p>({v?.role})</p>
                  <p className="team-des">
                   {v?.body}
                  </p>
                </div>
              </SwiperSlide>  
              ))}
            </Swiper>
          </div>
          <img src={bottomLine} alt="" />
        </div>
      </Container>
      {/* <a href="#roadmap"><button className='scrollTop mt-3' >↡</button></a> */}
    </div>
  );
};

export default OurTeam;
