import { useState, useRef, useEffect } from "react";
// import artistCard from "../../asset/artist/artistCard.png";
import artistCard2 from "../../asset/artist/artistCard2.jpg";
import artistJapan from "../../asset/artist/artistJapan.jpg";
import airroolChina from "../../asset/artist/airroolChina.jpg";
import demaTaiwan from "../../asset/artist/demaTaiwan.jpg";
import eichiKP from "../../asset/artist/eichiKP.jpg";
import evaUkraine from "../../asset/artist/evaUkraine.jpg";
import zeronextTaiwan from "../../asset/artist/zeronextTaiwan.jpg";
import nagiImg from "../../asset/artist/nagi.jpg";
import 倉科 from "../../asset/artist/倉科.jpg";
import 四葉 from "../../asset/artist/四葉.jpg";
// import slideImgRound from "../../asset/artist/roundSlider.png";
import ArrowDown from "../../../Component/asset/image/arrow-down.png";
import ArtistContentImgT from "../../../Component/asset/image/artist-drop-img-top.png";
import ArtistContentImgB from "../../../Component/asset/image/artist-img-drop-btm.png";
import pixivIcon from "../../../Component/asset/image/icons/pixiv.svg";
//
import arrowRight from "../../asset/artist/rightbtn.png";
import arrowLeft from "../../asset/artist/leftbtn.png";
//
import eichi_human from "../../asset/gallery/eichi_human.png";
import angel_neko from "../../asset/gallery/angel_neko.png";
import kitten_dema from "../../asset/gallery/kitten_dema.png";
import demon_airool from "../../asset/gallery/demon_airool.png";
import fairy_eva from "../../asset/gallery/fairy_eva.png";

// 
import none_img from "../../asset/gallery/none.png";
// 
import aboutUsArtMobile from "../../asset/image/artist_mobile_head.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, EffectCoverflow } from "swiper";
import Flag from "react-world-flags";
import "./Artists.css";
import { Fade } from "react-reveal";

const Artists = () => {
  const [swiper, setSwiper] = useState();
  const prevRef = useRef();
  const nextRef = useRef();

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  return (
    <div className="artist-body" id="artist">
      
      <img src={aboutUsArtMobile} className="artist_heading d-md-none mx-auto mb-5 w-100 d-block" alt="" />
      {/* <img src={aboutUsArt} className="artist_heading d-md-block d-none" alt="" /> */}

      <Fade bottom>
        <Swiper
          modules={[Navigation]}
          slidesPerView={4}
          spaceBetween={10}
          // navigation={true}
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current,
          }}
          loop={true}
          // initialSlide={
          breakpoints={{
            270: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          //   30: {
          //     slidesPerView: 2,
          //     spaceBetween: 14,
          //   },
          // 47: {
          //     slidesPerView: 3,
          //     spaceBetween: 20,
          //   },
            768: {
              slidesPerView: 3,
              spaceBetween: 35,
            },
            1024: {
              slidesPerView: 4,
            },
            1524: {
              slidesPerView: 5,
            },
            1924: {
              slidesPerView: 6,
            },
          }}
          onSwiper={setSwiper}
          className="mySwiper"
        >
          {/* 1 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "けだま",
                img: artistJapan,
                country: "Japan",
                code: "JP",
                race: "天使",
                body: `
                我是一位必須同時兼顧家庭與小孩的在職專業繪師，我大部分都是畫二創作品，但當我有機會出線的時候我也喜歡做出一些新的嘗試。
                非常感謝這個專案邀請我，雖然這對我來說是一個全新的挑戰，我會盡全力思考如何讓我的技巧更進步，用超越自我的決心來幫助這個專案成功。
                "
              `,
                pixiv: `https://www.pixiv.net/users/32304575`,
                twit: `https://twitter.com/mqq11`,
              }}
            />
          </SwiperSlide>
          {/* 2 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Eichi",
                img: eichiKP,
                country: "Korea",
                code: "KR",
                race: "人類",
                body: `Hi, 我是EICHI, 我自2019年開始在P站上傳我創作的作品。我有些作品是二創，但由於我喜歡將我的想法付諸實現，所以我主要還是以原創作品為主。
                過去我很少將科幻元素加入我的作品，所以FREEDEN專案對我來說是一個新的嘗試，我花了許多時間將一些我的巧思放進角色中，我希望你們會喜歡這些作品並將他們納入你的NFT收藏中。
             
               `,
                pixiv: `https://www.pixiv.net/users/35286171`,
                twit: `https://twitter.com/skskdi12z`,
              }}
            />
          </SwiperSlide>
          {/* 3 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Dema",
                img: demaTaiwan,
                country: "Taiwan",
                code: "TW",
                race: "貓耳族",
                body: `
                大家好，我是Dema，也可以叫我代碼
是一位喜歡畫唯美事物的插畫家
以下是經常使用的社群網站
如果喜歡我的創作的話，歡迎追蹤關注作品最新動態喔
               `,
               pixiv: `https://www.pixiv.net/users/7054584`,
                twit: `https://twitter.com/hmw59750476`,
              }}
            />
          </SwiperSlide>
          {/*  4 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Nagi",
                img: nagiImg,
                country: "Japan",
                code: "JP",
                race: "龍族",
                body: ` 哈囉~大家好! 我的名字是Nagi 。大學畢業後除了當個上班族外，有空閒的時間都會盡量拿來創作，主要是以水彩風格的作品為主，希望大家可以喜歡我的作品。
             
             
               `,
                pixiv: `https://www.pixiv.net/users/8095212`,
                twit: `https://twitter.com/shirona_775`,
              }}
            />
          </SwiperSlide>
          {/* 5 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Eva",
                img: evaUkraine,
                country: "Ukraine",
                code: "UA",
                race: "妖精",
                body: `
                嗨，我是Eva, 一位來自烏克蘭的數位藝術家。我大部分的作品主軸都是日漫風格的女性。我選擇日漫風格是因為我比較擅長以平面靜態畫作來表達情感。我特別喜歡一些比較老的動漫作品以及他們所涵載的懷舊情感。
                在這個專案創作中，我參考了""Chobits""這個作品。因為這個作品的女主角形象空靈、知性且脫俗，就像一個妖精一樣。我的目標就是發揮我的天賦，創造一個可愛並有相同特性的腳色，並讓他們盡可能貼近現實。
               `,
                insta: "https://www.instagram.com/evang3llium/",
                twit: `https://twitter.com/evangellium`,
              }}
            />
          </SwiperSlide>
          {/* 6 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "倉科ゆづき",
                img: 倉科,
                country: "HongKong",
                code: "HKG",
                race: "狐娘",
                body: `
                大家好，我叫倉科柚月，曾在遊戲公司擔任畫師，我的愛好是畫可愛狐娘及漂亮的美少女。
                最想把狐娘的美好推廣出去，喜歡的話，可以追蹤我並關注作品和最新動態喔
                
                `,
                pixiv: `https://www.pixiv.net/users/3398728`,
                twit: `https://twitter.com/KurashinaYuduki`,
              }}
            />
          </SwiperSlide>
          {/* 7 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "zeronext",
                img: zeronextTaiwan,
                country: "Taiwan",
                code: "TW",
                race: "機娘",
                body: `
                遊戲產業離開的插畫師，喜歡沉浸在各種機甲可能性
                愛好是畫些神秘感的美少女跟機械裝備，如果你也對機械元素感到熱愛，歡迎追蹤我的社群               
              
                `,
                pixiv: `https://www.pixiv.net/users/3449131`,
                twit: `https://twitter.com/SonZaiKanNai`,
              }}
            />
          </SwiperSlide>
          {/* 8 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "AiRool",
                img: airroolChina,
                country: "China",
                code: "CN",
                race: "惡魔",
                body: `建築學本科出身初入游戲美術行業的新人畫師，喜歡色彩明快的日系動漫美術風格。
                曾跟隨於原神項目組的前輩們學習與進修。
             
                `,
                pixiv: `https://www.pixiv.net/users/11173060`,
                twit: `https://twitter.com/C63025522`,
              }}
            />
          </SwiperSlide>
          {/* 9 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Avetetsuya StudiosP",
                country: "Indonesia",
                code: "ID",
                race: "鬼族",
                img: artistCard2,
                body: `
                Avetetsuya Studios於2020年成立位於印尼，以日漫風格為主的藝術接案工作室，專注在創造亞洲風格的2D與3D藝術作品。我們以工匠精神提供高品質的藝術設計、開發與創作服務。
                `,
                twit: `https://twitter.com/TetsunyanS`,
              }}
            />
          </SwiperSlide>
          {/* 9 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "四葉",
                country: "Japan",
                code: "JP",
                race: "人魚",
                img: 四葉,
                body: `
                在遊戲公司當一名繪師工作一段時間後，四葉於2014年決定離職成為一個自由工作者。從那時候開始，他涉略了很多不同產業的創作，包括手機遊戲、電腦遊戲、雜誌書籍等等。
                `,
                twit: ``,
              }}
            />
          </SwiperSlide>
        </Swiper>
      </Fade>
      <div className="custom_arrows_artist artist_slider_arrows right_cs_arrow" ref={nextRef}>
        <img src={arrowRight} alt="" />
      </div>
      <div className="custom_arrows_artist artist_slider_arrows left_cs_arrow" ref={prevRef}>
        <img src={arrowLeft} alt="" />
      </div>
    </div>
  );
};

// SwiperCore.use([Autoplay, Pagination, Navigation, EffectCoverflow]);

export const Gallery = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [swiper, setSwiper] = useState();
  const prevRef = useRef();
  const nextRef = useRef();

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  let galleryData = [
    {img: eichi_human, race: "人類"},
    {img: none_img, race: "龍族"},
    {img: none_img, race: "人魚"},
    {img: none_img, race: "機娘"},
    {img: angel_neko, race: "天使"},
    {img: kitten_dema, race: "貓耳族 "},
    {img: demon_airool, race: "惡魔"},
    {img: fairy_eva, race: "妖精"},
    {img: none_img, race: "鬼族"},
    {img: none_img, race: "狐娘"},
  ];

  return (
    <div className="py-5 bg-black">
      <div className="roundSlider  position-relative">
        <div className="roundSlider_container overflow-hidden">
          <div className="raseImg mb-5">
            <span>{galleryData[activeSlide]?.race}</span>
          </div>
          <div className="position-relative">
          <div className="gallery_swiper_main">
            <Swiper
              modules={[Navigation, EffectCoverflow]}
              slidesPerView={3}
              spaceBetween={100}
              effect={"coverflow"}
              centeredSlides
              onSlideChange={(d)=> setActiveSlide(d.realIndex)}
              coverflowEffect={{
                rotate: 0,
                // stretch: 10,
                // depth: 55,
                stretch: 80,
                depth: 200,
                modifier: 5,
                // initialSlide: 3,
                slideShadows: false,
              }}
              // navigation={true}
              navigation={{
                prevEl: prevRef?.current,
                nextEl: nextRef?.current,
              }}
              loop={true}
              // initialSlide={3}
              breakpoints={{
                // 270: {
                //   coverflowEffect: false,
                //   slidesPerView: 2,
                //   spaceBetween: 15,
                // },
                280: {
                  // coverflowEffect: false,
                  slidesPerView: 2,
                  spaceBetween: 50,
                  coverflowEffect: {
                    rotate: 0,
                    stretch: 48,
                    depth: 160,
                    modifier: 1,
                    // initialSlide: 3,
                    slideShadows: false,
                  },
                },
                780: {
                  coverflowEffect: {
                    rotate: 0,
                    // stretch: 10,
                    // depth: 55,
                    stretch: 44,
                    depth: 145,
                    modifier: 2,
                    // initialSlide: 3,
                    slideShadows: false,
                  },
                },
                1700: {
                  coverflowEffect: {
                    rotate: 0,
                    // stretch: 10,
                    // depth: 55,
                    stretch: 44,
                    depth: 175,
                    modifier: 2,
                    // initialSlide: 3,
                    slideShadows: false,
                  },
                },
              }}
              onSwiper={setSwiper}
              className="mb-5 overflow-visible gallery_coverflow_slider"
            >
              {galleryData?.map((v,i)=> (
                <SwiperSlide key={i}>
                <div className="galley_slider_img_wrapper">
                  <img className="" src={v.img} alt="" />
                </div>
              </SwiperSlide>
              ))}
{/*               
              <SwiperSlide>
                <img className="galley_slider_img" src={none_img} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={none_img} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={none_img} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={angel_neko} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={kitten_dema} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={demon_airool} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={fairy_eva} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={none_img} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={none_img} alt="" />
              </SwiperSlide> */}
            </Swiper>
           
          </div>
           {/*  */}
           <div className="custom_arrows_artist right_cs_arrow" ref={nextRef}>
              <img src={arrowRight} alt="" />
            </div>
            <div className="custom_arrows_artist left_cs_arrow" ref={prevRef}>
              <img src={arrowLeft} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ArtistCard = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="artist_card_box">
      <img
        className="img-card m-0 p-0 artist_card_img"
        src={data?.img}
        alt=""
      />
      <div className="artist-card-text">
        {/* <p>{data?.name} {" " } {data?.code ? <Flag height="16px" code={ data?.code }  fallback={ data?.country}/> : `(${data?.country})`}</p> */}
        <p>
          {data?.name}{" "}
          {data?.code ? (
            <Flag height="16px" code={data?.code} fallback={data?.country} />
          ) : (
            ""
          )}
        </p>
        {/* <p>{data?.name} {" " } {data?.code ? <Flag height="16px" code={ data?.code }  fallback={""}/> : null}</p> */}
        <p>{`(${data?.race})`}</p>
        <div
          className={
            open
              ? "artist-dropdown-content artist-dropdown-content-show"
              : "artist-dropdown-content"
          }
        >
          <div className="artist-content-img">
            <img src={ArtistContentImgT} alt="" />
          </div>
          <p className="px-2">{data?.body}</p>
          <div className="artist-content-img">
            <img src={ArtistContentImgB} alt="" />
          </div>
          <div className="artist-socials">
            {data?.fb ? (
              <a href={data?.fb} target="blank">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            ) : null}
            {data?.insta ? (
              <a href={data?.insta} target="blank">
                <i className="fa-brands fa-instagram"></i>
              </a>
            ) : null}
            {data?.pixiv ? (
              <a href={data?.pixiv} target="blank">
                {/* <i className="fa-solid fa-p"></i> */}
                <img className="pixivIcon" src={pixivIcon} alt="" />
              </a>
            ) : null}
            {data?.twit ? (
              <a href={data?.twit} target="blank">
                <i className="fa-brands fa-twitter"></i>
              </a>
            ) : null}
          </div>
        </div>
        <div
          className={open ? "img-arr img-arr-active" : "img-arr"}
          onClick={() => setOpen(!open)}
        >
          <img src={ArrowDown} alt="arrow" />
        </div>
      </div>
    </div>
  );
};

export default Artists;
