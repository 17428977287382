import React from "react";
import bannerLogo from "../../asset/image/bannerLogo.png";
import joinDiscord from "../../asset/image/joinDiscord.png";
import minitNow from "../../asset/image/minitNow.png";
import learnMore from "../../asset/image/bannerLearnMore.png";
import backgroundVideo from "../../asset/image/main_banner.mp4";
import bottomArt from "../../asset/image/bannerEdenMint.png";
import artist from "../../asset/image/artist.png";
import "./TopBanner.css";
import AboutUs from "../AboutUs/AboutUs";
import Navigation from "../../Shared/Navigation/Navigation";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

const TopBanner = () => {
  return (
    <div id="home" className="topbanner">
      <Navigation />
      <video
        className="banner-background"
        autoPlay
        loop
        muted
        disablePictureInPicture="true"
      >
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <Fade bottom>
        <div className="banner-top-content">
          <img className="img-fluid banner-logo" src={bannerLogo} alt="" />
          <a
            href="https://discord.com/invite/rnYW6fu3zv"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="banner-logo discord" src={joinDiscord} alt="" />
          </a>
          <Link to="/mint">
            <img className="banner-logo minit" src={minitNow} alt="" />
          </Link>
          <a href="#AboutUs">
            <img className="banner-logo learn" src={learnMore} alt="" />
          </a>
        </div>
      </Fade>

      <div>
        <Fade bottom>
          <div>
            <h1 className="mint-edn-heading text-uppercase">
              This is how{" "}
              <div className="d-md-inline d-block mt-md-0 mt-1">
                {" "}
                Everything Started
              </div>{" "}
            </h1>
            <Link to="/mint">
              <img
                className="banner-logo edenBox mint-edn-button px-4"
                src={bottomArt}
                alt=""
              />
            </Link>
          </div>
        </Fade>
      </div>
      <div className="artist pb-lg-0 d-md-block d-none">
        <img className="artist-img" src={artist} alt="" />
      </div>
      <AboutUs />
    </div>
  );
};

export default TopBanner;
