import React from "react";
import "./Voting.css";
import voting from "../../asset/image/voting.png";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";

// 
const Voting = () => {
  const [showVotingText, setshowVotingText] = React.useState(false)
    const ToggleTextVoting = () =>{
        setshowVotingText(!showVotingText)
    }
  return (
    <div className="chain-voting" id="voting">
      <Container>
        <Fade bottom>
          <div className="logo-part">
            <img className="img-fluid" src={voting} alt="" />
            <p className="giveWayHeading">鏈上投票</p>
          </div>
        </Fade>
        <Fade bottom>
          <div className="voting-des-box p-3 p-md-5 m-md-5 text-start">
            <p className="voting-des">
            我們相信我們的 NFT 持有者的意見將是社區成長的基礎。因此我們會開發投票系統，持有者可以對社區發展的方向進行投票，包括但不限於：
            </p>
            <ul className="voting-des">
              <li className="vot-list">● 贊助藝術家名單</li>
              <li className="vot-list">● 見面會/訪談邀請名單</li>
              <li className="vot-list">● 線上抽獎禮品項目</li>
              <li className="vot-list">
              ● 授予特定群體權利
              </li>
              <li className="vot-list">
              ● 可以在我們的虛擬世界展覽中展出的藝術品
              </li>
            </ul>
              <p className={showVotingText ? "voting-des show-voting-text-active" : "voting-des show-voting-text"}>
              我們會繼續把社群的意見作為重要的參考指標，讓持有者真正擁有權力參與到社區的發展中
每個成員擁有的 NFT 數量將決定投票權重。擁有的 NFT 越多，影響力就越高。
              </p>
              <div className="voting-learn-more">
                <button type="button" className="learn-more" onClick={ToggleTextVoting}>Learn More <i class="fa fa-chevron-down" aria-hidden="true"></i></button>
              </div>
            {/* <ul className='voting-des'>
                    <li className='listing'>Proposers are the only group in the system that can set up on-chain voting.</li>
                    <li className='listing'>The Executors are responsible for executing the voting process and the final results:</li>
                    <li className='listing'>we can assign this role to a person at a particular address to allow it to perform on
                    <li className='listing'>behalf of the original executor. </li>
                        Finally, the Admin role has the power to appoint different roles: this is a crucial and
                        sensitive role</li>
                </ul> */}
            {/* <p className='voting-des'>
                We will then create an application to allow users to vote on-chain and check the result.
                </p> */}
          </div>
        </Fade>
      </Container>
      {/* <a href="#bonus"><button className='scrollTop mt-3'  style={{position:"relative",zIndex:"2"}}>↡</button></a> */}
    </div>
  );
};

export default Voting;
