import { useState } from "react";
import { Container } from "react-bootstrap";
import banner from "../../Component/asset/image/metaverseBeg.jpg";
import Navigation2 from "../Shared/Navigation2/Navigation2";
import img1 from "../asset/mint/begLt.png";
import img2 from "../asset/mint/begRt.png";
import img3 from "../asset/mint/begLb.png";
import img4 from "../asset/mint/begRb.png";
import circle from "../asset/mint/mint.gif";
import endImg from "../asset/mint/desEnd.png";
import banner1 from "../asset/mint/story1.jpg";
import banner2 from "../asset/mint/fighting-angels-w.jpg";
import banner3 from "../asset/mint/freeden-new-world-w.jpg";
import "./Mint.css";
import Footer from "../Shared/Footer/Footer";

const MintNft = () => {
  const [showButton, SetshowButton] = useState(false);
  const [mintCount, setMintCount] = useState(1);

  const handleMintCount = (type) => {
    switch (type) {
      case "+":
        setMintCount(mintCount + 1);
        break;
      case "-":
        if (mintCount - 1 > 1) {
          setMintCount(mintCount - 1);
        } else {
          setMintCount(1);
        }
        break;
      default:
        return mintCount;
    }
  };
  return (
    <div>
      {/* <div className='banner' id='home'> */}
      <div className="navigation-position">
        <Navigation2 />
      </div>
      <div className="mint-banner-background-img"></div>
      {/* <img className='deskTwo-banner' src={banner} alt=""/> */}
      {/* </div> */}
      <div className="desktop-three-back">
        <Container>
          <div className="desk-3">
            <div className="minit-res">
              {/* <p>
                    Thousands of years ago, before the peace that we know today, the world knew of chaos. It
                    ripped through society, crumbling structures and decaying peace with little resistance. The
                    world’s only hope was a group of wise and powerful Sages. Blessed with divine power, the sages came together to cast out the chaos, banishing it from
                    our mortal plane. In order to prevent the resurrection of this evil, they created a powerful
                    rune that would be called upon if evil ever showed its face again.
                    Now it is happening again… Chaotic energy has seeped its way into the world once more,
                    and the holy rune has been deployed to save humanity. Split into five thousand sealed
                    genesis bondings, the rune’s mission is to find those not yet tainted by chaos and save
                    them. Once a bonding has been successfully initiated, a guardian angel is deployed to watch over
                    the individual that has been blessed with it. They must be guided to a new world, one where
                    they can be safe forever. They must find FreEden FREEDEN is a holy realm whose strength and protection from chaos come with each
                    successful bond finding its way there. Therefore, each of the five thousand bonds must find
                    their way to this holy realm, to restore the strength of the people, and invoke the true power
                    of the guardian angels.
                    </p> */}

              {/* border images */}
              <img className="corner1" src={img1} alt="" />
              <img className="corner2" src={img2} alt="" />
              <img className="corner3" src={img3} alt="" />
              <img className="corner4" src={img4} alt="" />

              {/*  */}
              <div className="new-story-section">
                <div className="mb-md-5 mb-3">
                  <img className="img-fluid" src={banner1} alt="story one" />
                </div>
                <p>
                  在早被人們遺忘的數千年前，黑暗降臨，世界即將被吞噬。黑暗的肆虐帶來了天崩地裂：力量所到之處如摧枯拉朽，無人可擋。彼時山河破碎，人類正面臨一場緩慢且恥辱的敗亡。
                  正當所有人都毫無希望地等待末日降臨，有十位賢者為了拯救世界現身了。
                  雖然賢者共同召喚出神聖能量並遏制了邪惡所帶來的浩劫，但他們依舊擔心邪惡有朝一日會捲土重來。為了將黑暗徹底逐出，他們通過分離各自的一部分靈魂，並將其融為一體，創造了一個強大的符文、強大到他們以為能讓他們珍視的世界徹底遠離混沌……
                </p>

                <div className="my-4 text-center">
                  {showButton ? null : (
                    <button
                      type="button"
                      className="learn-more mx-auto"
                      onClick={() => SetshowButton(true)}
                    >
                      Learn More{" "}
                      <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </button>
                  )}
                </div>
                {showButton && (
                  <>
                    <div className="my-md-5 my-3">
                      <img className="img-fluid" src={banner2} alt="" />
                    </div>
                    <p>
                      但他們不知道所有的努力只不過是暴風雨前的寧靜...。
                      在征服世界的野心被阻止數千年後，邪惡又將洶洶來襲。渾沌能量伺機而動，黑暗如烏雲壟罩天空，人類暫時的繁榮宛如即將被刺骨的寒風吹散的春天花朵。
                      面對鋪天蓋地而來的邪惡力量，賢者的神聖符文無法抵禦邪惡所帶來的毀滅，在無情的衝擊下這股賢者們所遺留下來的防禦能量支離破碎，化成六千枚創世聖約，成為了守護人世的最後希望。
                      在賢者聖能破碎成聖約之後，已無力保全這個岌岌可危、分崩離析的世界。所以這些聖約剩下的唯一任務就是：拯救那些少數尚未被黑暗吞噬的人。
                      肩負著賢者保護世界的遺願，聖約們消耗自己最終的力量，召喚出能保護並淨化世間的使者:
                      御靈使，以引領那未受汙染的靈魂進入最後的庇護所：FREEDEN。而最終，只有成功締結聖約的人能在御靈使的護佑下到達，其餘凡人則淪為亂世螻蟻。
                      現在，這個時刻即將到來….混沌能量已經趁虛而入，為了拯救世界，聖約現世。在化為六千位御靈使後，並開始尋找並保護那些未受渾沌侵染之人。
                    </p>
                    <div className="my-md-5 my-3">
                      <img className="img-fluid" src={banner3} alt="" />
                    </div>
                    <p>
                      一旦御靈使找到命定之人，此人將受御靈使淨化及守護。他們必須與御靈使一同領受天啟，協力找到一個全新的界域，一個無懈可擊的庇護所，他們必須找到FREEDEN，並一同建造一個嶄新的國度。
                      在一眾御靈使細心地引領下，一個嶄新的世界即將誕生。請忘卻過往的苦痛，並開始探索FREEDEN吧，在這裡你將與你的御靈使永世同存。
                      FREEDEN是一個神聖界域，它的力量與保護力是由成功到達的御靈使們的部分能量交織而成的。所以每個被召喚至現世的御靈使都必須想盡辦法找到這個神聖界域，才能夠滋長祂們主人的生命能量，並且覺醒祂們原本的力量。
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="pt-5 mt-2 img-container">
            <h2 className="text_pr">鑄造專區</h2>
            <img className="img-fluid circle-img" src={circle} alt="" />
            <div className="row my-md-4 my-1">
              <button
                onClick={() => handleMintCount("-")}
                className="trianglebtnLeft"
              ></button>
              <div className="mint_count_box d-flex justify-content-center align-items-center">
                {mintCount}
              </div>
              <button
                onClick={() => handleMintCount("+")}
                className="trianglebtnRight"
              ></button>
            </div>
            <img className="img-fluid endImg" src={endImg} alt="" />
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default MintNft;
