import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import eventLogo from "../../asset/metaEvents/eventsLogo.png";
import giveWayLogo from "../../asset/giveway/giveWayLogo.png";
import img1 from "../../asset/giveway/img1.png";
import img2 from "../../asset/giveway/img2.png";
import img3 from "../../asset/giveway/img3.png";
import img4 from "../../asset/giveway/img4.png";
import img5 from "../../asset/giveway/img5.png";
import img6 from "../../asset/giveway/img6.png";

import "./GiveWay.css";
import { Fade } from "react-reveal";

const GiveWay = () => {
  return (
    <div className="giveWay" id="giveaway">
      <div className="wrapper">
        <Container>
          <Fade bottom>
            <div>
              <img className="mt-5 img-fluid pb-4" src={giveWayLogo} alt="" />
              <p className="giveWayHeading">
              每週抽獎和贈品
              </p>
              <p className="metaHeading fs-5">
              來自不同藝術家的免費 NFT 空投
              </p>
              <p className="meta-description mb-5">
              想要更多您最喜歡的數位藝術收藏嗎？我們將與全球有才華的 ACG 藝術家合作，將他們介紹給我們的會員，並將他們的作品以抽獎和空投的形式發送給我們的會員。
              </p>
            </div>
          </Fade>
          <div className="mt-5 pb-5">
            <Fade bottom>
              <div className="giveaway-cards-gb">
                <Row xs={1} md={2} className="g-5 mx-0 px-0">
                  <Col>
                    <Card className="eventCard">
                      <Card.Img variant="top" src={img1} />
                    </Card>
                  </Col>
                  <Col>
                    <Card className="eventCard">
                      <Card.Img variant="top" src={img2} />
                    </Card>
                  </Col>
                </Row>
              </div>
            </Fade>
          </div>
          <Fade bottom>
            <div>
              <img className="mt-5" src={eventLogo} alt="" />
              <p className="lotery-text">每週抽獎</p>
              <p className="lotery-text fs-6">
              我們每週舉行抽獎活動，提供各種獎品：
              </p>
              <div className="coins">
                <img className="img-fluid-coins" src={img3} alt="" />
                <img className="img-fluid-coins" src={img4} alt="" />
                {/* <img className="img-fluid" src={img3} alt="" />
                <img className="img-fluid" src={img4} alt="" /> */}
              </div>
              <div className="coin-des">
                <p>
               
                  <span className="under-coin">加密貨幣: </span>
                  穩定幣 / ETH / BNB / 其他加密貨幣
                   <br />
                  <span className="under-coin">
                  數位點數卡: 
                  </span>
                  {" "} 亞馬遜/Netflix/Steam 禮品卡等
                  <br />
                  <span className="under-coin">實體獎品: </span>                  
滑鼠墊 / 移動電源 / 連帽衫 / T恤 /帽子
                </p>
              </div>
              <div>
                <p className="meta-description fs-6">
                  {/* FREEDEN 2D NFT holders can mint our 3D NFT for free. FREEDEN
                  3D NFTs not only have a unique appearance but also have
                  exclusive utilities and rewards. <br /> */}
                  <br />
                  我們每週舉行抽獎活動，提供各種禮物，包括加密貨幣、
                   <br /> <br />
                   想要更多獨特的 ACG 藝術品收藏嗎？我們將與全球有才華的藝術家合作，將他們介紹給我們的會員，並抽獎和空投他們的作品。
                </p>
              </div>
            </div>
          </Fade>
          

          <Fade bottom>
            <div className="mx-md-5">
              <img className="mt-5" src={eventLogo} alt="" />
              <p className="lotery-text">免費鑄造 FREEDEN 3D NFT </p>
              <p className="give-ways-des coin-des px-2">
              初期就持有我們2D NFT 持有者可以免費鑄造我們的 3D NFT。 FREEDEN 3D NFT 版本是對我們的支持者和 ACG 社區的進一步承諾和感謝，表達了我們對 ACG 產業的一貫信念。這些 3D NFT 不僅價值更高，持有者還將獲得額外的會員福利！更好的是，您持有的 FREEDEN NFT（2D 和 3D）越多，您獲得的好處就越多，在未來的抽獎活動中獲得的中獎機也會越高!
              </p>
            </div>
          </Fade>
          <div className="mt-5 pb-5 mx-md-5 px-md-4">
            <Fade bottom>
              <div className="giveaway-cards-2-bg">
                <Row xs={1} md={2} className="g-5 mx-0 px-0">
                  <Col>
                    <Card className="eventCard mx-2">
                      <Card.Img variant="top" src={img5} />
                    </Card>
                  </Col>
                  <Col>
                    <Card className="eventCard mx-2">
                      <Card.Img variant="top" src={img6} />
                    </Card>
                  </Col>
                </Row>
              </div>
            </Fade>
          </div>
          <Fade bottom>
            {/* <div className='mx-md-5 pb-5'>
            <img className='mt-5' src={eventLogo} alt="" />
                <p className='lotery-text'>
                       3D Freden NFT giveaways
                </p>
                <p className='give-ways-des coin-des pb-5'>
                    All FreEden holders will get a free Freeden 3D for free. The 3D version of FreEden NFT is a
                    further promise and appreciation to our supporters and ACG communities, which expresses
                    our consistent faith in the ACG industry. These 3D NFTs not only have higher value, but the
                    holders will have additional member benefits! Again for our appreciation to all initial
                    supporters, these NFTs will be distributed to them; they only need to cover the gas fee. Even
                    better is that the more FreDen NFTs (both 2D & 3D) you hold, the more benefits you get, and
                    the higher winning chance you get in future raffle events!
                </p>
            </div> */}
          </Fade>
        </Container>
      </div>
      {/* <a href="#offline"><button className='scrollTop mb-5' style={{position:"relative",zIndex:"2"}}>↡</button></a> */}
    </div>
  );
};

export default GiveWay;
