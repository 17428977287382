import React from 'react';
import { Container } from 'react-bootstrap';
import ourMissionLogo from '../../asset/metaEvents/eventsLogo.png';
import './ContactUs.css'
const ContactUs = () => {
    return (
        <div>
            <Container>
                <div className='contactus-beg'>
                   <img src={ourMissionLogo} alt="" />
                   <p className='contact'>CONTACT US</p>
              
              <div>
               <form>
                   <div className='form-style'>
                        <input className='input-style' type="text" />
                        <input className='input-style' type="text" />
                        <textarea className='input-style' name="" id="" cols="30" rows="10"></textarea>
                        <button className='input-style'>SEND</button>
                   </div>
                </form>
              </div>
              
                </div>
                
                <div>
                  
                </div>
            </Container>
        </div>
    );
};

export default ContactUs;