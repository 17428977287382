import React from 'react';
import { Container } from 'react-bootstrap';
import roadmapLogo from '../../asset/roadmap/roadmapLogo.png'
import img1 from '../../asset/roadmap/parse1.png'
import img2 from '../../asset/roadmap/parse2.png'
import img3 from '../../asset/roadmap/parse3.png'
import img4 from '../../asset/roadmap/parse4.png'
import img5 from '../../asset/roadmap/parse5.png'
import img6 from '../../asset/roadmap/parse6.png'
import mob1 from '../../asset/roadmap/mobile1.png'
import mob2 from '../../asset/roadmap/mobile2.png'
import mob3 from '../../asset/roadmap/mobile3.png'
import mob4 from '../../asset/roadmap/mobile4.png'
import mob5 from '../../asset/roadmap/mobile5.png'
import mob6 from '../../asset/roadmap/mobile6.png'
import './Roadmap.css'
import { Fade } from 'react-reveal';


const Roadmap = () => {
    return (
        <div className='roadmap' id="roadmap">
            <Container>
                <div className="roadmap-wraper">
                    <Fade bottom>
                    <div className='pt-5'>
                    <img className='img-fluid' src={roadmapLogo} alt="" />
                    </div>
                    </Fade>
                   
                   {/* parse/// */}
                    <Fade bottom>
                    <div className='parse-step mt-5'>
                          <img className='img-fluid parse-img' src={img1} alt="" />
                          <img className='img-fluid mob-img' src={mob1} alt="" />
                          <div className='parse-des'>
                              <ul>
                                    <li> 在社群平台開始建立知名度</li>
                                    <li> 網站上線</li>
                                    <li> 招聘社群專員</li>
                                    <li> 舉辦社區活動</li>
                                    <li> 建立 FREEDEN NFT 智能合約</li>
                              </ul>
                          </div>   
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='parse-step mt-5'>
                          <img className='img-fluid parse-img' src={img2} alt="" />
                          <img className='img-fluid mob-img' src={mob2} alt="" />
                          <div className='parse-des'>
                              <ul>
                                <li> NFT 預售</li>
                                <li> NFT 公開發售</li>
                                <li> 更多抽獎活動</li>
                                <li> 台北國際動漫節線下活動7/28~8/1</li>
                                <li> 東京Comike線下活動8/13~8/14</li>
                                <li> 啟動藝術家獎金計劃</li>             
                              </ul>
                          </div>   
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='parse-step mt-5'>
                          <img className='img-fluid parse-img' src={img3} alt="" />
                          <img className='img-fluid mob-img' src={mob3} alt="" />
                          <div className='parse-des'>
                            <ul>
                              <li>元宇宙員工培訓</li>
                              <li>開設元宇宙俱樂部</li>
                              <li>第一次 Vtuber 見面會</li>
                              <li>舉辦專屬線上拍賣</li>
                              </ul>
                          </div>   
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='parse-step mt-5'>
                          <img className='img-fluid parse-img' src={img4} alt="" />
                          <img className='img-fluid mob-img' src={mob4} alt="" />
                          <div className='parse-des'>
                              <ul>
                              <li> 舉辦首場線上藝術家展</li>
                              <li> 舉辦首場動漫音樂祭</li>
                              <li> 線下活動Anime NYC 11/18-11/20</li>
                              </ul>
                          </div>   
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='parse-step mt-5'>
                          <img className='img-fluid parse-img' src={img5} alt="" />
                          <img className='img-fluid mob-img' src={mob5} alt="" />
                          <div className='parse-des'>
                             <ul>
                              <li> 發送免費 3D NFT 給2D持有者</li>
                              <li> 3D NFT 預售e</li>
                              <li> 3D NFT 公開發售</li>
                              <li> 宣布 3D NFT 持有者獨有的新賦能</li>
                              <li> 啟動 3D NFT 獎品池</li>
                          </ul>
                          </div>   
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='parse-step mt-md-5 last_rd_step'>
                          <img className='img-fluid parse-img' src={img6} alt="" />
                          <img className='img-fluid mob-img' src={mob6} alt="" />
                          <div className='parse-des'>
                              <ul>
                               <li> 啟動鏈上投票平台</li>
                               <li>  加入基於社區意見的更多功能，讓粉絲和藝術家群體更加茁壯</li>
                              </ul>
                          </div>   
                    </div>
                    </Fade>
                </div>
            </Container>
        </div>
    );
};

export default Roadmap;