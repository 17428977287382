import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay, Pagination } from "swiper";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import eventLogo from "../../asset/metaEvents/eventsLogo.png";
import evennt1 from "../../asset/metaEvents/event1.png";
import evennt2 from "../../asset/metaEvents/event2.png";
import evennt3 from "../../asset/metaEvents/event3.png";
import evennt4 from "../../asset/metaEvents/event4.png";
import evennt5 from "../../asset/metaEvents/event5.png";
import evennt6 from "../../asset/metaEvents/event6.png";
import topLine from "../../asset/metaEvents/topLine.png";
import bottomLine from "../../asset/metaEvents/bottomLine.png";
import "./MetaEvents.css";

const MetaEvents = () => {
  let data = [
    {
      img: evennt1,
      title: `1. 元宇宙動漫展覽:`,
      body: `我們將邀請多位動漫藝術家參展，展示他們獨特的ACG藝術作品，舉辦見面會和座談會，帶我們的會員進入一個稀有而獨特的藝術世界。`,
    },
    {
      img: evennt2,
      title: `2.動漫音樂祭:`,
      body: `邀請ACG歌手和聲優參加我們的元宇宙演唱會。我們致力於將不同的體驗帶到虛擬舞台上，讓我們的會員結識更多的人才，鼓勵新進藝術家持續努力，吸引更多的會員加入ACG領域。`,
    },
    {
      img: evennt3,
      title: `3. Vtuber見面會:`,
      body: `舉辦Vtuber元宇宙見面會，讓粉絲可以在元宇宙與自己喜歡的偶像互動，一起玩小遊戲，甚至可以參加專屬的私人或小型演唱會。`,
    },
    {
      img: evennt4,
      title: `4.專屬線上拍賣會:`,
      body: `我們將與全球ACG藝術家合作，為FREEDEN會員打造獨家動漫作品。藝術品將以 NFT 的形式出售，並且搭配實體商品配送，拍賣將會在我們的 Discord 或 Metaverse中進行，以確保會員可以買到自己喜歡的作品。每個想要參加競標的人都必須完成身份驗證並連接他們的區塊鏈錢包，以確保交易安全並防止搗亂者和惡意競標。
    `,
    },
  ];
  return (
    <div className="metaEvents" id="metaevent">
      <div>
        <Fade bottom>
          <img className="mt-5" src={eventLogo} alt="" />
          <p className="metaHeading">Metaverse ACG Events</p>
          <p className="meta-description mb-5">
            元宇宙動漫展覽 / 動漫音樂祭 / Vtuber見面會 / 專屬線上拍賣
            <br />
            我們未來將通過社區投票舉辦更多的元宇宙活動。
          </p>
        </Fade>
      </div>
      <Container>
        <img className="img-fluid mb-5" src={topLine} alt="" />
        <div className="desktop-metaevents">
          <div className="text-start">
            <Row xs={1} md={2} className="g-5 mx-0 px-0">
              {data?.map((v, i) => (
                <Col key={i}>
                  <Fade bottom>
                    <Card className="eventCard">
                      <Card.Body>
                        <Card.Title className="event-title">
                          {v?.title}
                        </Card.Title>
                        <Card.Text className="event-text">{v?.body}</Card.Text>
                      </Card.Body>
                      <Card.Img variant="top" src={v.img} />
                    </Card>
                  </Fade>
                </Col>
              ))}
            </Row>
          </div>
        </div>
        <div className="mobile-metaevents">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[FreeMode, Autoplay, Pagination]}
            className="mySwiper"
          >
            {data?.map((v, i) => (
              <SwiperSlide>
                <Fade bottom>
                  <Card className="eventCard">
                    <Card.Body>
                      <Card.Title className="event-title">
                        {v?.title}
                      </Card.Title>
                      <Card.Text className="event-text">{v?.body}</Card.Text>
                    </Card.Body>
                    <Card.Img variant="top" src={v.img} />
                  </Card>
                </Fade>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <img className="img-fluid mt-5 mb-5 pb-5" src={bottomLine} alt="" />
        {/* <a href="#exibition"><button className='scrollTop mb-5'>↡</button></a> */}
        <div className="px-2">
          <Fade bottom>
            <img src={eventLogo} alt="" />
          </Fade>
          <Fade bottom>
            <p className="metaHeading" id="exibition">
            網上個人收藏展
            </p>
          </Fade>
          <Fade bottom>
            <p className="meta-description ms-md-5 me-md-5 ps-md-5 pe-md-5">
            我們理解粉絲們也希望有機會展示他們的收藏和品味，同時表達他們對他們欣賞的藝術家的支持並與其他粉絲分享。
借助我們在元宇宙擁有的空間，會員有機會舉辦他們的收藏品展示會，讓收集了多個 NFT 的人能夠展示他們的收藏品並安排自己的展覽空間。他們可以邀請朋友，我們也可以幫他們宣傳他們所舉辦的展覽會
            </p>
          </Fade>
        </div>
        <div></div>

        <div className="mt-5 pb-5">
          <Fade bottom>
            <div className="exibition-imgs-bg">
              <Row xs={1} md={2} className="g-5 mx-0 px-0">
                <Col>
                  <Card className="eventCard">
                    <Card.Img variant="top" src={evennt5} />
                  </Card>
                </Col>
                <Col>
                  <Card className="eventCard">
                    <Card.Img variant="top" src={evennt6} />
                  </Card>
                </Col>
              </Row>
            </div>
          </Fade>
        </div>
      </Container>
      {/* <a href="#giveaway"><button className='scrollTop mb-5'>↡</button></a> */}
    </div>
  );
};

export default MetaEvents;
