import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { Fade } from "react-reveal";
import utlogo from "../../asset/artist/utilitiesLogo.png";
// css
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import "./Utilities.css";


const Utilities = () => {

  // utility text
  let utilityText = [
    {
      title: `✦Stageverse 專屬元宇宙俱樂部:`,
      body: `我們將在元界舉辦各種大型活動，包括展覽、vtuber聚會、動漫音樂會……只要你想得出來，就可以有無止盡的可能性。
    `,
    },
    {
      title: `✦元宇宙展覽活動:`,
      body: `FREEDEN的 NFT 持有者可以免費進入俱樂部；我們會聘請真人化身為接待員以及每週舉辦活動，使會員們可以在俱樂部盡情享受社交與玩樂的樂趣。`,
    },
    {
      title: `✦每週抽獎和贈品:`,
      body: `持有者可以免費 Mint 我們的 3D NFT，並參加我們每週的抽獎活動，獲得各種獎品。通過社群提案和投票，可以將更多不同的獎品添加到獎池中。`,
    },
    {
      title: `✦線下賦能和活動:`,
      body: `專為ACG愛好者提供的線下實用賦能：全球動漫展支援、排隊服務、線下動漫展實體派對`,
    },
    {
      title: `✦鏈上投票:`,
      body: `我們是高度社群驅動的組織。通過我們專門開發的平台，NFT 持有者可以對社區的提案進行投票，以確定社區未來的發展方向。`,
    },
    {
      title: `✦特別紅利:`,
      body: `持續持有FREEDEN NFT能讓持有者獲得特別待遇，購買超過1個NFT的持有者還將享受專屬福利。`,
    },
  ];
  return (
    <div id="utilities" className="utilities">
      <div>
        <Fade bottom>
          <img src={utlogo} alt="" />
        </Fade>
        <p className="utilities-heading">U T I L I T Y</p>
      </div>
      <div className="utilities-points desktop-utility pb-md-5">
        <ul>
          {utilityText?.map((v, i) => (
            <Fade bottom key={i}>
              <li>
                <p>
                  <span className="point-heading">{v.title}</span>
                  {v.body}
                </p>
              </li>
            </Fade>
          ))}
        </ul>
      </div>
      <div className="utilities-points pb-md-5">
        <div className="mobile-utility-swiper">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
              {utilityText?.map((v, i) => (
            <SwiperSlide key={i}>
              <ul className="m-0 p-0">
                <li className="mb-2">
                  <span className="point-heading">
                   {v?.title}
                  </span>
                  <p>
                    <br />
                   {v?.body}
                  </p>
                  <br />
                </li>
              </ul>
            </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Utilities;
