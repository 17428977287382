import { useState } from "react";
import { Link } from "react-router-dom";
import "./Navigation.css";

const Navigation = () => {
  const [menu, setMenu] = useState(false);

  const closeMenu =() => {
    setMenu(false)
  }
  return (
    <>
     <button onClick={()=> setMenu(!menu)} id="cs_menuToggle" className={menu ? "opened_nav" : ""}>
        <span></span>
        <span></span>
        <span></span>
      </button>
    <nav id="cs_navigation" className={menu ? "opened_nav" : ""}>
      <ul id="cs_menu">
        <a href="javascript:void(0)" onClick={() => {window.scrollTo(0, 0); closeMenu()}}>
          <li>首頁</li>
        </a>
        <a href="#AboutUs" onClick={closeMenu}>
          <li>關於我們</li>
        </a>
        <a href="#utilities" onClick={closeMenu}>
          <li>賦能</li>
        </a>
        <a href="#ourTeam" onClick={closeMenu}>
          <li>團隊介紹</li>
        </a>
        <Link to="/artistRecruit">
          <li>藝術家招募</li>
        </Link>
        <Link to="/mint">
          {" "}
          <li>NFT鑄造專區</li>
        </Link>
        {/* <a href="https://mint.freeden.io" target='_blank' rel="noreferrer"><li>Mint Page</li></a> */}
        <a
          href="https://freeden.gitbook.io/wpzh/"
          target="_blank"
          rel="noreferrer"
        >
          <li>白皮書</li>
        </a>
        <hr />
        <p className="text_pr mb-3">
        語言選擇
        </p>
        <a href="https://freeden.io/" rel="noreferrer"><li>{">"} English</li></a>
        <a href="https://jp.freeden.io/" rel="noreferrer"><li>{"> "} 日本語</li></a>
      </ul>
    </nav>
   {menu && <div className="nav_backdrop" onClick={closeMenu}></div>}
    </>
  );
};

export default Navigation;
