import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import corner1 from "../../asset/roadmap/leftCorner.png";
// import corner2 from "../../asset/roadmap/rightCorner.png";
import desktop2 from "../../asset/roadmap/desktop2.png";
import "./Card.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

const Card = () => {
  let cardsData = [
    {
      body: `
     為ACG創作者提供一對一的輔導課程，並教他們如何創作NFT，解釋細節，並基於我們對於區塊鏈市場的研究，替創作者們提供策略建議。
    `,
    },
    {
      body: `
       透過穩定成長的社群力量，讓我們的創作者能夠持續增加粉絲。舉辦線上或實體聚會，提供獨特且寶貴的交流機會。 
    `,
    },
    {
      body: `
      推動獎學金計劃，資助一些業餘愛好者創作。利用NFT籌措時所獲得的資金，幫助有天份的人才能夠獲得實質的協助，鼓勵更多ACG創作者加入我們的平台。 
    `,
    },
    {
      body: `
      舉辦工作坊，聘請業界經驗豐富的ACG創作者，向年輕的創作者們分享如何找到自己成功的方法。 
    `,
    },
    {
      body: `
      使用部分收益購買元宇宙空間，將藝術展覽搬到網路上。希望能夠改變ACG作品的展覽文化，突破因疫情多數展覽取消的限制，幫助我們的創作者們得到更多的收益。
    `,
    },
  ];
  return (
    <div>
      <Container>
        <div className="card-background">
          <p className="card-heading fw-bold">我們將提供以下協助：</p>
          <div className="d-md-block d-none">
            <Row className="card-first-row">
              {cardsData?.map((v, i) => (
                <Col key={i} className="col-12 mb-3 col-md-4">
                  <div className="card-box p-3 ">
                    <img className="corner-left" src={corner1} alt="" />
                    <h1 className="heading-num">{i + 1}.</h1>
                    <p className="guide-description">{v?.body}</p>
                  </div>
                </Col>
              ))}
              <Col className="col-md-2"></Col>
            </Row>
            <div>
              <img className="mt-5 img-fluid pb-5" src={desktop2} alt="" />
            </div>
          </div>
          <div className="d-md-none d-block">
            <Swiper
              slidesPerView={1}
              spaceBetween={24}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper pb-3 text_card_slider"
            >
              {cardsData?.map((v, i) => (
                <SwiperSlide key={i}>
                  <div className="card-box p-3 m-4">
                    <img className="corner-left" src={corner1} alt="" />
                    <h1 className="heading-num text-start mt-4 ps-1 text_pr_dark">
                      {i + 1}.
                    </h1>
                    <p className="guide-description">{v?.body}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Card;
