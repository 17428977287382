import React from "react";
import { Container } from "react-bootstrap";
import metaLogo from "../../asset/image/metaVerse.png";
// import metaVideo from "../../asset/image/loopableversions.mp4";
import "./MetaVerse.css";
import { Fade } from "react-reveal";

const MetaVerse = () => {
  return (
    <div className="metaverse" id="metaverse">
      <Container>
        <Fade bottom>
          <img className="mt-5 pt-5 img-fluid" src={metaLogo} alt="" />
        </Fade>
        <Fade bottom>
          <p className="metaHeading mt-4">
            Stageverse Exclusive Metaverse Club
          </p>
          <p className="ms-md-5 me-md-5 ps-md-5 pe-md-5 meta-description">
          在遊歷了不同的元宇宙之後，我們決定在 Stageverse 建立我們的第一個虛擬世界。Stageverse 是由洛杉磯的一家元宇宙科技公司開發的，致力於減少人類對物理與虛擬空間的體驗差距，具有無限的創意和表現潛力，我們選擇 Stageverse 的主要原因是它們的設計非常出色，用戶體驗流暢，並且兼容手機和 VR 設備。
我們的 NFT 持有者可以免費進入俱樂部，由聘請真人接待員服務，以及每週會舉辦活動；會員們可以在俱樂部裡自由地社交和玩樂。
          </p>
        </Fade>
        <Fade bottom>
          <div className="metaVerseVideo">
            {/* <video width="90%" controls>
                    <source src="https://www.youtube.com/embed/7teB25DjciI" type="video/mp4"/>
  
                    Your browser does not support HTML video.
                </video> */}
            <embed
              className="metaVerseVide"
              src="https://www.youtube.com/embed/18XtkJDb3Ig"
              title="YouTube video player"
              frameborder="0"
              allow="autoplay"
              allowfullscreen
            ></embed>
          </div>
        </Fade>
      </Container>
    </div>
  );
};

export default MetaVerse;
